/* eslint-disable react/prop-types */
import React from 'react';
import FGFCalculator from '../../../components/fgfCalculator';
import RepaymentCalculatorComplex from '../../../components/repaymentCalculatorComplex';
import ProofPoints from '../../../components/proofPoints';
import TestimonialsComponent from '../../../components/testimonials';
import FAQComponent from '../../../components/faq';
import EndOfPage from '../components/endOfPage/endOfPage.component';
import LandingBanner from '../components/banner';

const NimbleSmallLoan = ({ componentData }) => {
  const bannerData = componentData.filter(
    item => item.__typename === 'ContentfulNimblePageHeadingSection'
  )[0];
  const calculatorDataOld = componentData.filter(
    item => item.__typename === 'ContentfulNimbleRepaymentCalculatorComplex'
  )[0];
  const calculatorData = componentData.filter(
    item => item.__typename === 'ContentfulFgfCalculator'
  )[0];
  const proofPointsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentProofPoints'
  )[0];
  const testimonialsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentTestimonials'
  )[0];
  const FAQsData = componentData.filter(
    data => data.__typename === 'ContentfulComponentFaq'
  )[0];
  return (
    <>
      {bannerData && <LandingBanner data={bannerData} />}
      {calculatorDataOld && <RepaymentCalculatorComplex data={calculatorDataOld} />}
      {calculatorData && <FGFCalculator data={calculatorData} />}
      {proofPointsData && <ProofPoints data={proofPointsData} />}
      {testimonialsData && (
        <TestimonialsComponent data={testimonialsData} />
      )}
      {FAQsData && <FAQComponent data={FAQsData} />}
      {bannerData && (
        <EndOfPage
          applyLink={bannerData.applyNowLink}
          applyName="Nimble Small Personal Loan"
        />
      )}
    </>
  );
};

export default NimbleSmallLoan;
